import React from "react";
import PageLayout from "@components/PageLayout/PageLayout";
import * as styles from "./Eln.module.scss";
import { JoinInstitutionsV2 } from "../../landingpagev2/_components/_JoinInstitutionsV2/_JoinInstitutionsV2";
import { TypographyV2, TrustedByV2, HighPerformer } from "@components/index";
import { PartnersV2 } from "pages/landingpagev2/_components/_Partners/_Partners";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

export default function Notebook() {
	return (
		<PageLayout
			seoOptions={{
				type: "PREDEFINED",
				pageName: "eln",
			}}
		>
			<div className={styles.container}>
				<StaticImage
					src={"./_assets/eln_hero.png"}
					alt="Genemod’s ELN: Best Electronic Lab Notebook Software 2024"
				/>
				<span className={styles.titleAndSummary}>
					<TypographyV2
						variant="HEADING_1"
						weight="MEDIUM"
						tabletVariant="HEADING_1"
						mobileVariant="HEADING_1"
						style={{ marginBottom: 12 }}
					>
						Genemod’s ELN: Best Electronic Lab Notebook Software
						2024
					</TypographyV2>
					<TypographyV2
						variant="BODY_TEXT_EXTRA_LARGE"
						tabletVariant="BODY_TEXT_EXTRA_LARGE"
						mobileVariant="BODY_TEXT_EXTRA_LARGE"
						color="gray-600"
						className={styles.summary}
					>
						Discover how ELN enhances storing experiment data and
						collaborate with research team in real-time.
					</TypographyV2>
				</span>
			</div>
			<div className={styles.content}>
				<TypographyV2
					variant="HEADING_2"
					weight="MEDIUM"
					tabletVariant="HEADING_2"
					mobileVariant="HEADING_2"
					color="text-secondary"
                    className={styles.h2Blog}
				>
					What is an Electronic Lab Notebook?
				</TypographyV2>
				<TypographyV2
					variant="BODY_TEXT_MEDIUM"
					tabletVariant="BODY_TEXT_MEDIUM"
					mobileVariant="BODY_TEXT_MEDIUM"
					color="text-helper"
                    className={styles.pBlog}
				>
					In the digital era, managing vast amounts of research data
					can be overwhelming. Traditional paper-based lab notebooks
					can be prone to loss, damage, or disorganization. This is
					where Electronic Lab Notebooks (ELNs) shine.  An Electronic
					Lab Notebook (ELN) is a software solution that is designed
					to replace a paper lab notebook or basic spreadsheet. They
					are used by scientists in many fields, particularly in the
					life sciences. ELNs have numerous advantages, and the
					benefits will be countless if it is used along with LIMS.
				</TypographyV2>
				<StaticImage
					src={"./_assets/eln_live_editing_file.png"}
					alt="Live Edit File"
				/>
				<TypographyV2
					variant="HEADING_2"
					weight="MEDIUM"
					tabletVariant="HEADING_2"
					mobileVariant="HEADING_2"
					color="text-secondary"
                    className={styles.h2Blog}
				>
					Benefits of Using an Electronic Lab Notebook
				</TypographyV2>
				<TypographyV2
					variant="BODY_TEXT_MEDIUM"
					tabletVariant="BODY_TEXT_MEDIUM"
					mobileVariant="BODY_TEXT_MEDIUM"
					color="text-helper"
                    className={styles.pBlog}
				>
					Some labs prefer to use a traditional database such as
					papers and Excel. However, this type of spreadsheet has its
					limitations. A fully digitized lab notebook, on the other
					hand, has many more advantages, including:
					<ul>
						<li>
							<b>Data Organization and Accessibility:</b>{" "}
							Researchers can store and organize their data in a
							structured and searchable manner. From experimental
							protocols to observations and results, all
							information is readily accessible, enabling
							efficient data retrieval and analysis with data
							management systems.
						</li>
						<li>
							<b>Collaboration and Sharing:</b> ELNs facilitate
							real-time collaboration by providing a centralized
							platform where researchers can collaborate on
							experiments, share data, and provide feedback.
						</li>
						<li>
							<b>Data Security and Backups:</b> Digital Lab
							Notebook employs encryption and user access controls
							to ensure data privacy. Additionally, automatic
							backups safeguard against data loss due to hardware
							failure or other unforeseen events.
						</li>
						<li>
							<b>Version Control and Audit Trail:</b> Researchers
							can track modifications made to their entries,
							ensuring transparency and enabling easy
							identification of any changes or discrepancies.
						</li>
						<li>
							<b>Integration with Other Tools:</b> ELN software
							integrates seamlessly with analysis tools, such as
							statistical software or image analysis platforms.
						</li>
					</ul>
				</TypographyV2>
				<TypographyV2
					variant="HEADING_2"
					weight="MEDIUM"
					tabletVariant="HEADING_2"
					mobileVariant="HEADING_2"
					color="text-secondary"
                    className={styles.h2Blog}
				>
					Introducing Genemod’s Electronic Lab Notebook (ELN) Software
				</TypographyV2>
				<TypographyV2
					variant="BODY_TEXT_MEDIUM"
					tabletVariant="BODY_TEXT_MEDIUM"
					mobileVariant="BODY_TEXT_MEDIUM"
					color="text-helper"
                    className={styles.pBlog}
				>
					As a leading provider of laboratory informatics solutions,
					Genemod offers a state-of-the-art Electronic Lab Notebook
					(ELN) software. Genemod's ELN software is designed to
					optimize data management, data collection, collaboration,
					and research efficiency. With its user-friendly interface
					and lightweight ELN for Life Sciences R&D teams, researchers
					can effortlessly document experiments, record observations,
					and store results, all within a centralized digital
					environment. Genemod's ELN software ensures data security
					and regulatory compliance. The platform generates audit
					trails for transparency and traceability. This data
					management system approach safeguards intellectual property
					and eases compliance demonstration during audits. The
					software also integrates smoothly with various laboratory
					instruments and analysis tools, such as Microsoft Excel,
					allowing researchers to import data directly into the ELN
					and analyze it within the platform. This integration
					enhances data integrity and streamlines research workflows,
					saving valuable time and minimizing errors. Genemod's ELN
					software fosters an efficient and productive research
					environment; researchers can focus on their core work and
					feel confident that their data is securely managed and
					easily accessible.
				</TypographyV2>
				<StaticImage
					src={"./_assets/protocol_preview.png"}
					alt="Protocol Preview"
				/>
				<TypographyV2
					variant="HEADING_2"
					weight="MEDIUM"
					tabletVariant="HEADING_2"
					mobileVariant="HEADING_2"
					color="text-secondary"
                    className={styles.h2Blog}
				>
					What Makes Genemod’s ELN Better Than Other Electronic Lab
					Notebook Software?
				</TypographyV2>
				<TypographyV2
					variant="BODY_TEXT_MEDIUM"
					tabletVariant="BODY_TEXT_MEDIUM"
					mobileVariant="BODY_TEXT_MEDIUM"
					color="text-helper"
                    className={styles.pBlog}
				>
					Genemod offers ELN software that has a centralized
					dashboard, to optimize data management. Collaborators can
					organize data, track progress, and set priorities for lab
					projects. Once work is uploaded to the protocol library, it
					can be shared with other researchers. Some of the main
					features include:
				</TypographyV2>
				<TypographyV2
					variant="HEADING_4"
					weight="MEDIUM"
					tabletVariant="HEADING_4"
					mobileVariant="HEADING_4"
					color="text-secondary"
                    className={styles.h4Blog}
				>
					Enhance the specificity of these points to spotlight client
					features
				</TypographyV2>
				<TypographyV2
					variant="BODY_TEXT_MEDIUM"
					tabletVariant="BODY_TEXT_MEDIUM"
					mobileVariant="BODY_TEXT_MEDIUM"
					color="text-helper"
                    className={styles.pBlog}
				>
					<li>
						Centralized dashboard with shared real-time access to
						updates
					</li>
					<li>
						Easy cross-over and connection with each notebook page
					</li>
					<li>
						Permanent archiving for future sharing of reproducible
						experiments
					</li>
					<li>
						A virtual workspace allowing organization and sharing of
						all experimental protocols
					</li>
					<li>Real-time collaboration to reduce human error</li>
					<li>Secure storage of data for future retrieval</li>
				</TypographyV2>
				<TypographyV2
					variant="HEADING_4"
					weight="MEDIUM"
					tabletVariant="HEADING_4"
					mobileVariant="HEADING_4"
					color="text-secondary"
                    className={styles.h4Blog}
				>
					Genemod AI
				</TypographyV2>
				<TypographyV2
					variant="BODY_TEXT_MEDIUM"
					tabletVariant="BODY_TEXT_MEDIUM"
					mobileVariant="BODY_TEXT_MEDIUM"
					color="text-helper"
                    className={styles.pBlog}
				>
					Genemod employed a game-changing ChatGPT feature in its
					Electronic Lab Notebook. With Genemod AI, scientists can now
					improve research reports by simply choosing different tones,
					checking grammars, and getting a draft of the research
					report based on the research data. Check out the one-of-a
					kind AI feature on ELN here.
				</TypographyV2>
			</div>
			<div className={styles.mobileOnly}>
				<PartnersV2 />
				<HighPerformer
					containerClass={styles.performerContainer}
					badgeClass={styles.performerBadge}
					scoreClass={styles.score}
					starsClass={styles.stars}
				/>
			</div>
			<div className={styles.hideMobile}>
				<TrustedByV2 />
			</div>
			<JoinInstitutionsV2 />
		</PageLayout>
	);
}
