// extracted by mini-css-extract-plugin
export var BODY = "Eln-module--BODY--22bhO";
export var BODY_TEXT_EXTRA_LARGE = "Eln-module--BODY_TEXT_EXTRA_LARGE--2HAoy";
export var BODY_TEXT_EXTRA_SMALL = "Eln-module--BODY_TEXT_EXTRA_SMALL--cgo8y";
export var BODY_TEXT_LARGE = "Eln-module--BODY_TEXT_LARGE--VWwPC";
export var BODY_TEXT_LARGE_V2 = "Eln-module--BODY_TEXT_LARGE_V2--esgpE";
export var BODY_TEXT_MEDIUM = "Eln-module--BODY_TEXT_MEDIUM--3vJML";
export var BODY_TEXT_SMALL = "Eln-module--BODY_TEXT_SMALL--Yxwdw";
export var BODY__newLayout = "Eln-module--BODY__newLayout--o4jf2";
export var BOLD = "Eln-module--BOLD--yLVHQ";
export var BUTTON = "Eln-module--BUTTON--4smSN";
export var BUTTON__newLayout = "Eln-module--BUTTON__newLayout--iLnc7";
export var CAPTION = "Eln-module--CAPTION--KlmT0";
export var CAPTION__newLayout = "Eln-module--CAPTION__newLayout--NBuQ0";
export var COMMON = "Eln-module--COMMON--0+LaL";
export var DESCRIPTION = "Eln-module--DESCRIPTION--2ljPf";
export var DESCRIPTION__newLayout = "Eln-module--DESCRIPTION__newLayout--V2iN0";
export var FOOTER = "Eln-module--FOOTER--2ALYw";
export var FOOTERBOLD = "Eln-module--FOOTERBOLD--CG9H-";
export var FOOTER__newLayout = "Eln-module--FOOTER__newLayout--64BxH";
export var HEADER1 = "Eln-module--HEADER1--TcFSq";
export var HEADER1__newLayout = "Eln-module--HEADER1__newLayout--MuY+c";
export var HEADER2 = "Eln-module--HEADER2--xfZpE";
export var HEADER2__newLayout = "Eln-module--HEADER2__newLayout--8cEXn";
export var HEADER3 = "Eln-module--HEADER3--3P8Ku";
export var HEADER3__newLayout = "Eln-module--HEADER3__newLayout--Qq9NF";
export var HEADER4 = "Eln-module--HEADER4--dKCxS";
export var HEADER4__newLayout = "Eln-module--HEADER4__newLayout--yWE-h";
export var HEADING = "Eln-module--HEADING--VAxic";
export var HEADING_1 = "Eln-module--HEADING_1--pm-+R";
export var HEADING_2 = "Eln-module--HEADING_2--tr5VD";
export var HEADING_3 = "Eln-module--HEADING_3--fof6u";
export var HEADING_4 = "Eln-module--HEADING_4--Ef776";
export var HEADING_5 = "Eln-module--HEADING_5--dmTe3";
export var HERO = "Eln-module--HERO---wQyc";
export var HERO__newLayout = "Eln-module--HERO__newLayout--wGlIM";
export var MEDIUM = "Eln-module--MEDIUM--n01OI";
export var MOBILE_BODY_TEXT_EXTRA_LARGE = "Eln-module--MOBILE_BODY_TEXT_EXTRA_LARGE--PsKbm";
export var MOBILE_BODY_TEXT_EXTRA_SMALL = "Eln-module--MOBILE_BODY_TEXT_EXTRA_SMALL--3n0z9";
export var MOBILE_BODY_TEXT_LARGE = "Eln-module--MOBILE_BODY_TEXT_LARGE--MHOoV";
export var MOBILE_BODY_TEXT_LARGE_V2 = "Eln-module--MOBILE_BODY_TEXT_LARGE_V2--IH3yS";
export var MOBILE_BODY_TEXT_MEDIUM = "Eln-module--MOBILE_BODY_TEXT_MEDIUM--uM0ic";
export var MOBILE_BODY_TEXT_SMALL = "Eln-module--MOBILE_BODY_TEXT_SMALL--QODHy";
export var MOBILE_HEADING_1 = "Eln-module--MOBILE_HEADING_1--Vk7+N";
export var MOBILE_HEADING_2 = "Eln-module--MOBILE_HEADING_2--dg4Iw";
export var MOBILE_HEADING_3 = "Eln-module--MOBILE_HEADING_3--wvuYo";
export var MOBILE_HEADING_4 = "Eln-module--MOBILE_HEADING_4--VkWLg";
export var MOBILE_HEADING_5 = "Eln-module--MOBILE_HEADING_5--AQE37";
export var QUOTE = "Eln-module--QUOTE--Ff1cn";
export var REGULAR = "Eln-module--REGULAR--N7nyL";
export var SEMIBOLD = "Eln-module--SEMIBOLD--kQEjk";
export var SUBHEADERDESC = "Eln-module--SUBHEADERDESC--A2ce7";
export var SUBHEADERDESCBOLD = "Eln-module--SUBHEADERDESCBOLD--QQEpD";
export var SUBHEADERDESC__newLayout = "Eln-module--SUBHEADERDESC__newLayout--ZflXf";
export var SUBTITLE = "Eln-module--SUBTITLE--WIzGg";
export var SUBTITLE__newLayout = "Eln-module--SUBTITLE__newLayout--3ygWI";
export var TABLET_BODY_TEXT_EXTRA_LARGE = "Eln-module--TABLET_BODY_TEXT_EXTRA_LARGE--j-wGK";
export var TABLET_BODY_TEXT_EXTRA_SMALL = "Eln-module--TABLET_BODY_TEXT_EXTRA_SMALL--MWgSB";
export var TABLET_BODY_TEXT_LARGE = "Eln-module--TABLET_BODY_TEXT_LARGE--JyOBb";
export var TABLET_BODY_TEXT_LARGE_V2 = "Eln-module--TABLET_BODY_TEXT_LARGE_V2--RJ-VW";
export var TABLET_BODY_TEXT_MEDIUM = "Eln-module--TABLET_BODY_TEXT_MEDIUM--sq13O";
export var TABLET_BODY_TEXT_SMALL = "Eln-module--TABLET_BODY_TEXT_SMALL--JRkru";
export var TABLET_HEADING_1 = "Eln-module--TABLET_HEADING_1--nwVAf";
export var TABLET_HEADING_2 = "Eln-module--TABLET_HEADING_2--EM7vE";
export var TABLET_HEADING_3 = "Eln-module--TABLET_HEADING_3--XEez5";
export var TABLET_HEADING_4 = "Eln-module--TABLET_HEADING_4--K5bxY";
export var TABLET_HEADING_5 = "Eln-module--TABLET_HEADING_5--9Qzc+";
export var blogSynopsis = "Eln-module--blogSynopsis--xjpPS";
export var button = "Eln-module--button--D49GT";
export var caseStudyInfo = "Eln-module--caseStudyInfo--2WlQk";
export var caseStudyInfoCol = "Eln-module--caseStudyInfoCol--0QnBH";
export var caseStudyInfoHeader = "Eln-module--caseStudyInfoHeader--3tB7z";
export var center = "Eln-module--center--LKiKV";
export var container = "Eln-module--container--dPbne";
export var content = "Eln-module--content--CDEbQ";
export var date = "Eln-module--date--R6Nxo";
export var detailsContainer = "Eln-module--detailsContainer--Xub3Z";
export var fadeContainer = "Eln-module--fadeContainer--mHfTh";
export var featuredImage = "Eln-module--featuredImage--9LdVe";
export var h2Blog = "Eln-module--h2Blog--CtY7q";
export var h4Blog = "Eln-module--h4Blog--FILbM";
export var header = "Eln-module--header--pMoad";
export var hideMobile = "Eln-module--hideMobile--7ACRJ";
export var image = "Eln-module--image--8beRh";
export var left = "Eln-module--left--3qD2W";
export var limitWidthOnLargeScreens = "Eln-module--limitWidthOnLargeScreens--2PAVz";
export var mobileOnly = "Eln-module--mobileOnly--KTXlV";
export var pBlog = "Eln-module--pBlog--VLhEd";
export var performerBadge = "Eln-module--performerBadge--WwlRW";
export var performerContainer = "Eln-module--performerContainer--730MS";
export var postContainer = "Eln-module--postContainer--EjtBd";
export var right = "Eln-module--right--qxnZI";
export var score = "Eln-module--score---KGFs";
export var stars = "Eln-module--stars--q4pNm";
export var summary = "Eln-module--summary--D7H-f";
export var templateContainer = "Eln-module--templateContainer--gw6FR";
export var title = "Eln-module--title--poesC";
export var titleAndSummary = "Eln-module--titleAndSummary--HcKll";